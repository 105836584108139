import React, { Fragment, memo } from 'react';
import moment from 'moment';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Anchor from '../Anchor';
import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Images from '../Images';
import Video from '../Embed';

import proptypes from '../proptypes';

import Text from '../Text';

const { Image } = Images;

const COMPONENT_NAME = 'FullListItem';

const Header = ({ datetime, host, title }) => (
  <div className="row" style={{ marginBottom: '10px' }}>
    <div
      className="col s12"
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      <div className="row black raised pad margin-b-0">
        <div className="col s12 m6 l4 margin-tb">
          <Button
            classNames="btn btn-lg btn-primary fluid blue white-text center-align"
            title="Edit"
          >
            <Icon icon="edit" />
          </Button>
        </div>
        <div className="col s12 m6 l4 margin-tb">
          <Button
            classNames="btn btn-lg btn-primary fluid orange white-text center-align"
            title="Unpublish"
          >
            <Icon icon="do_not_disturb" />
          </Button>
        </div>
        <div className="col s12 m12 l4 margin-tb">
          <Button
            classNames="btn btn-lg btn-primary fluid red white-text center-align"
            title="Delete"
          >
            <Icon icon="delete" />
          </Button>
        </div>
      </div>
    </div>
    <div className="col s12">
      <div className="row">
        { !!datetime && (
          <div
            className="col s4 black white-text center-align raised show-date"
            style={{
              paddingTop: '2px',
            }}
          >
            <div className="row" style={{ margin: '0' }}>
              <div className="col s12">
                { moment(datetime).format('MMMM') }
              </div>
              <div className="col s12 font-size-3">
                { moment(datetime).format('DD') }
              </div>
            </div>
          </div>
        )}
        { !!host && (
          <div className="col s8 black valign-wrapper raised show-host">
            <div className="fluid center-align">
              <Text classNames="card-title font-size-2">
                { host }
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
    { !!title && (
      <div className="col s12 card-title ttyl-grey raised pad-tb">
        <Text classNames="font-size-3" styles={{ lineHeight: '2rem' }}>
          { title }
        </Text>
      </div>
    )}
  </div>
);

const Tags = ({ artists, genres }) => (
  <Fragment>
    <div className="row" style={{ margin: '10px' }}>
      { !!artists && (
        artists.map(artist => (
          <div
            className="chip black white-text raised"
            key={JSON.stringify(artist)}
          >
            {artist}
          </div>
        ))
      )}
    </div>
    <div className="row" style={{ margin: '10px' }}>
      { !!genres && (
        genres.map(genre => (
          <div className="chip raised" key={JSON.stringify(genre)}>
            {genre}
          </div>
        ))
      )}
    </div>
  </Fragment>
);

const Flyer = ({ title, flyer }) => (
  !!flyer && (
    <div className="col s12 m6" style={{ padding: 0 }}>
      <div className="row" style={{ margin: '0 0 5px 0' }}>
        <img
          id={`${title} Flyer`}
          className="raised"
          alt={`${title} Flyer`}
          src={flyer}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  )
);

const Purchase = ({
  title,
  showid,
  func,
  url,
  soldOut,
  ownPurchaseChildren,
  edit,
}) => (
  <Fragment>
    {
      ownPurchaseChildren ||
      <div style={{ margin: '10px 0' }}>
        { !!func && !!func.run && !!showid && (
          <Button
            classNames={func.classNames || 'btn green fluid raised'}
            onClick={() => func.run(showid)}
            styles={{ height: '50px' }}
          >
            {func.text || 'Get Ticket(s)'}
          </Button>
        )}
        { !!url && !!url.href && (
          <Anchor
            classNames={url.classNames || 'btn black fluid raised'}
            href={!!edit ? url.href : `${url.href}/edit`}
            styles={{ height: '50px' }}
          >
            {url.text || 'Get Ticket(s)'}
          </Anchor>
        )}
        { !!soldOut && !!title && (
          <Button
            classNames={soldOut.classNames || 'btn red fluid raised'}
            onClick={() => (
              !!edit
                ?
                `${url.href}/edit`
                :
                alert(
                  `${title} is sold out! Please check out our other events!`
                )
            )}
            styles={{ height: '50px' }}
          >
            {soldOut.text || 'Sold Out'}
          </Button>
        )}
      </div>
  }
  </Fragment>
);

// onEditorStateChange={this.onEditorStateChange}
const EditDesc = ({ description }) => (
  <Editor
    editorState={{}}
    toolbarClassName="toolbarClassName"
    wrapperClassName="wrapperClassName"
    editorClassName="editorClassName"
    onEditorStateChange={() => true}
  />
);

const Description = ({ title, description, purchase, showid, edit }) => (
  !!description && (
    <div className="col s12 m6" style={{ padding: 0 }}>
      {
        edit ? <EditDesc description={description} /> : (
        (description.length > 300)
          ?
          <Fragment>
            <div
              className="row ttyl-grey show-desc extended"
              style={{
                padding: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '25vh',
                margin: 0,
                zIndex: 0,
              }}
            >
              { description }
            </div>
            <Modal
              id="FullShowDescriptionModal"
              open="Read More"
            >
              { !!title && (
                <div className="col s12 card-title black pad-tb margin-b">
                  <Text classNames="font-size-5" styles={{ lineHeight: '2rem' }}>
                    { title }
                  </Text>
                </div>
              )}
              <div className="col s12 white">
                <p className="card-info black-text" style={{ padding: '15px' }}>
                  { description }
                </p>
              </div>
            </Modal>
          </Fragment>
          :
          <div
            className="row ttyl-grey show-desc"
            style={{
              padding: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: '25vh',
              margin: 0,
              zIndex: 0,
            }}
          >
            { description }
          </div>
        )
      }
    </div>
  )
);

const Content = ({
  title,
  artists,
  genres,
  flyer,
  description,
  purchase,
  showid,
  edit,
}) => (
  <div className="row" style={{ marginBottom: '10px' }}>
    <Purchase {...purchase} title={title} showid={showid} edit={edit} />
    <Tags genres={genres} artists={artists} />
    <Flyer title={title} artists={artists} genres={genres} flyer={flyer} />
    <Description
      title={title}
      description={description}
      purchase={purchase}
      showid={showid}
    />
    <Purchase {...purchase} title={title} showid={showid} edit={edit} />
  </div>
);

const Footer = ({ disclaimer, posted }) => (
  (disclaimer || posted) && (
    <div className="row ttyl-grey raised">
      { !!disclaimer && (
        <div
          className="col s12 white-text"
          style={{ padding: '10px' }}
        >
          <Text>
            { disclaimer }
          </Text>
        </div>
      )}
      { !!posted && (
        <div
          className="col s12 secondary-content"
          style={{ padding: '10px' }}
        >
          <Text>
            Posted: { moment(posted).format('MMMM Do YYYY, h:mm:ss a') }
          </Text>
        </div>
      )}
    </div>
  )
);

const Media = ({ media }) => (
  !!media && (media.length > 0) && (
    <div className="row ttyl-grey raised">
      { media.map(({ type, src, alt, title }) => (
        <div className="col s12 m6 l4">
          {(type === 'image') && (
            <Image src={src} alt={alt || title} title={title || alt} />
          )}
          {(type === 'video') && (
            <Video src={src} alt={alt || title} title={title || alt} />
          )}
        </div>
      ))}
    </div>
  )
);

const FullItem = ({
  showid,
  eventid, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  host,
  title,
  name, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  flyer,
  artists,
  genres,
  datetime,
  posted,
  tickets, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  // vip,
  description,
  disclaimer,
  purchase,
  media,
  edit,
}) => (
  <div className="col s12">
    <div className="center-align">
      <Header datetime={datetime} host={host} title={name || title} />
      <Content
        title={name || title}
        artists={artists}
        genres={genres}
        flyer={flyer}
        description={description}
        purchase={tickets || purchase}
        showid={eventid || showid}
        edit={edit}
      />
      <Footer disclaimer={disclaimer} posted={posted} />
      <Media media={media} />
    </div>
  </div>
);

FullItem.displayName = COMPONENT_NAME;

FullItem.propTypes = {
  showid: proptypes.typeCheck.string,
  genre: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.arrOfStr,
  datetime: proptypes.typeCheck.string,
  posted: proptypes.typeCheck.obj,
  tickets: proptypes.typeCheck.obj,
  vip: proptypes.typeCheck.arrOfObj,
  description: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  purchase: proptypes.typeCheck.obj,
};

FullItem.defaultProps = {
  showid: false,
  genre: false,
  host: false,
  title: false,
  flyer: false,
  artists: false,
  datetime: false,
  posted: false,
  tickets: false,
  vip: false,
  description: false,
  disclaimer: false,
  purchase: false,
};

export default memo(FullItem);
