import React from 'react';

const Button = ({
  id,
  classNames,
  disabled,
  styles,
  title,
  type,
  onClick,
  children,
}) => (
  <button
    id={id}
    type={type || 'button'}
    className={classNames || 'btn btn-primary uppercase'}
    style={{ cursor: 'pointer', ...styles }}
    title={title}
    onClick={onClick}
    disabled={disabled}
  >
    { children || title }
  </button>
);

export default Button;
