import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import withFirebaseAuth from 'react-with-firebase-auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import firebaseConfig from './Config/firebase';

import FUNCTIONS from './Functions';
import USER from './Data/User';
import NAVIGATION from './Data/Navigation';

import Navigation from './Modules/Navigation';
import Login from './Modules/Login';
import Shows from './Modules/Shows';
import Account from './Modules/Account';
import Contact from './Modules/Contact';
import Footer from './Modules/Footer';
import NotFound from './Modules/NotFound';
import News from './Modules/News';
import Terms from './Modules/Terms';

import Preloader from './Modules/components/Preloader';
import Subscribe from './Modules/components/Mailchimp/Subscribe';
import Promote from './Modules/components/Mailchimp/Promote';

const { NAV } = FUNCTIONS;

const firebaseApp = firebase.initializeApp(firebaseConfig);

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      auth: false,
      loading: true,
    };
  }

  componentDidMount(prevProps) {
    const { history } = this.props;

    this.unlisten = history.listen((location, action) => {
      window.scrollTo(0, 0);
      NAV.hideSideNav();
    });

    this.setLoader({ loading: false });
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user) {
      const nextUser = USER.getUserPropsObject(user);
      const prevUser = USER.getUserPropsObject(prevProps);
      if (
        !(isEqual(nextUser, prevUser))
        && !(isEqual(this.state.user, nextUser))
      ) {
        this.setUser({
          auth: true,
          user: nextUser,
        });
      }
    }
  }

  setLoader = ({ loading }) => (
    setTimeout(() => this.setState({ loading }), 500)
  );

  logOut = e => {
    const { history, signOut } = this.props;

    this.setUser({
      auth: false,
      history,
      signOut,
      user: null,
    });
    e.persist();
  };

  setUser = ({
    auth,
    history,
    signOut,
    user,
  }) => {
    this.setState({ user, auth });

    if (!user && !auth && !!history && !!signOut) {
      signOut();
      this.reset(history);
    }
  };

  reset = ({ push }) => push('/');

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const {
      history,
      signInWithGoogle,
      signInWithFacebook,
    } = this.props;
    const { auth, user, loading } = this.state;
    const { location: { pathname: route } } = history;

    return (
      <Fragment>
        <Navigation
          auth={auth}
          links={NAVIGATION.links}
          logOut={this.logOut}
          route={route}
        />
        <Subscribe />
        <Promote />
        <Preloader id="AppLoader" />
        { loading && <Preloader id="AppLoader" />}
        <div
          id="root-body"
          className="container white-text pad"
          style={{ display: 'none'}}
        >
          {
            /**
            *  route to diffrent component
            */
          }
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <Login
                  id="LoginPortalRouteAuto"
                  auth={this.state.auth}
                  signInWithGoogle={signInWithGoogle}
                  signInWithFacebook={signInWithFacebook}
                />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/account"
              component={() => (
                <Account auth={this.state.auth} user={user} history={history} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/news"
              component={() => (
                <News auth={this.state.auth} user={user} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/shows"
              component={() => (
                <Shows
                  auth={this.state.auth}
                  header={true}
                  user={user}
                  history={history}
                />
              )}
              state={this.state}
            />
            <Route
              path="/show/:id"
              component={({ match }) => (
                <Shows
                  auth={this.state.auth}
                  id={
                    (!!match && !!match.params && !!match.params.id)
                      ?
                      match.params.id
                      :
                      false
                  }
                  user={user}
                  history={history}
                />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/Contact"
              component={() => (
                <Contact auth={this.state.auth} user={user} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/terms"
              component={() => (
                <Terms auth={this.state.auth} user={user} />
              )}
              state={this.state}
            />
            <Route
              component={() => (
                <NotFound auth={this.state.auth} user={user} />
              )}
              state={this.state}
            />
            <Redirect to="/login" />
          </Switch>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

App.propTypes = {
  // History
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({}),
    action: PropTypes.string,
  }).isRequired,
  // Firebase
  user: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      displayName: PropTypes.string,
    }),
  ]),
  signInWithGoogle: PropTypes.func.isRequired,
  signInWithFacebook: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

App.defaultProps = {
  user: false,
};


export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(withRouter(App));
