import React, { Fragment } from 'react';

const Text = ({ children, classNames, styles, title, label }) => (
  <Fragment>
    {
      !children && (title || label)
        ?
        <span className={classNames} style={styles}>
          { title || label }
        </span>
        :
        <span className={classNames} style={styles}>
          { children }
        </span>
    }
  </Fragment>
);

export default Text;
