import React, { memo } from 'react';

import './Loader.css';

const ttylLogo = 'https://storage.googleapis.com/ttylevents-cdn/assets/ttyl_logo_white.png';

const Loader = ({ id, logo }) => (
  <div id={id} className="loader-layover">
		<div className="loader-layover-wrapper">
			<img
        className="loader-icon pulse"
        alt="TTYL Content Loader"
        src={logo || ttylLogo}
      />
      <div className="loader-volume">
        <div className="lds-facebook">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	</div>
);

export default memo(Loader);

//
// import React from 'react';
//
// const Loader = ({ id, color, type, style, view }) => (
//   <div id={id} className="center-align" style={{ height: '125vh', width: '50%' }}>
//     <div
//       className={`progress ${color ? color : 'blue accent-1'}`}
//       style={{ top: '35vh', position: 'relative', left: '50%' }}
//     >
//       <div
//         className={type ? 'determinate' : 'indeterminate'}
//         style={style}
//       />
//     </div>
//   </div>
// );
//
// export default Loader;
