import React, { Fragment } from 'react';

import ListItem from './ListItem';
import FullItem from './FullItem';

import proptypes from '../proptypes';

const List = ({ shows, showid, pastShows, edit }) => (
  (!!shows && shows.length > 0)
    ?
    (!!showid ?
      shows.filter((show) => (show.showid === showid))
      :
      shows
    ).map(show => (
      <Fragment key={JSON.stringify(show)}>
        { !!showid
            ?
            <FullItem
              view={!!showid}
              edit={edit}
              { ...show }
              key={JSON.stringify(show)}
            />
            :
            <ListItem
              view={!!showid}
              edit={edit}
              { ...show }
              key={JSON.stringify(show)}
            />
        }
      </Fragment>
    ))
    :
    <Fragment>
      {
        pastShows &&
        (!!showid ?
          pastShows.filter((show) => (show.showid === showid))
          :
          pastShows
        ).map(show => (
          <Fragment key={JSON.stringify(show)}>
            { !!showid
                ?
                <FullItem
                  view={!!showid}
                  edit={edit}
                  { ...show }
                  key={JSON.stringify(show)}
                />
                :
                <ListItem
                  view={!!showid}
                  edit={edit}
                  { ...show }
                  key={JSON.stringify(show)}
                />
            }
          </Fragment>
        ))
      }
    </Fragment>
);

List.displayName = 'ShowsList';

List.propTypes = {
  shows: proptypes.typeCheck.arrOfObj,
  showid: proptypes.typeCheck.string,
};

List.defaultProps = {
  shows: false,
  showid: false,
};

export default List;
