import React, { Fragment, PureComponent } from 'react';

import Anchor from './components/Anchor';
import Button from './components/Button';

import config from '../Config/index.js';

const { APPNAME, COLORS, ASSETS } = config;

const { LIGHT_LOGO } = ASSETS;

const COMPONENT_NAME = 'Navigation';

const isSameRoute = ({ link, href, route }) => (
  ((link || href) && route) && (
    (route.includes(link) || route === link)
    || (route.includes(href) || route === href)
  ) && (
    (link.length > 1) || (href.length > 1)
  )
);

const LineItemLink = props => (
  <li className={`${isSameRoute(props) && 'active'}`}>
    <Anchor
      id={JSON.stringify(props)}
      title={props.name}
      to={props.link}
      classNames="white-text waves-effect waves-light"
    />
  </li>
);

const LogOutButton = ({ onClick }) => (
  <Fragment>
    <li
      className="hide-on-large-only"
      style={{
        padding: '10px',
        marginTop: '0px',
        marginLeft: '10px',
        marginRight: '0',
      }}
    >
      <Button
        onClick={onClick}
        title="Log Out"
        classNames={
          `white btn btn-primary fluid ${COLORS.second}-text waves-effect waves-light`
        }
        styles={{ height: '48px' }}
      />
    </li>
    <li
      className="hide-on-med-and-down"
      style={{
        padding: '10px 0',
        marginTop: '-10px',
        marginLeft: '15px',
        marginRight: '0',
      }}
    >
      <Button
        onClick={onClick}
        title="Log Out"
        classNames={
          `white btn btn-primary fluid ${COLORS.second}-text waves-effect waves-light`
        }
        styles={{ height: '36px' }}
      />
    </li>
  </Fragment>
);

const LoginButton = () => (
  <li>
    <Anchor
      id="LinkToLoginOrSignUp"
      title="Login/Register"
      to="/"
      classNames="btn btn-primary waves-effect waves-light hide-on-large-only"
    />
    <Anchor
      id="LinkToLoginOrSignUp"
      title="Login/Register"
      to="/"
      classNames="btn btn-primary waves-effect waves-light margin-r-0 hide-on-med-and-down"
    />
  </li>
);

const SessionButtons = ({ auth, isMobile, logOut }) => (
  <Fragment>
    { auth ? <LogOutButton onClick={logOut} /> : <LoginButton />}
  </Fragment>
);

const renderAuth = ({ links, route }) => (
  <Fragment>
    {
      links.filter(
        (link) => (link.auth === true)
      ).map((ownLink) => (
        <LineItemLink route={route} { ...ownLink } key={JSON.stringify(ownLink)} />
      ))
    }
  </Fragment>
);

const renderUnAuth = ({ links, route }) => (
  <Fragment>
    { links && (
      links.filter(
        (link) => (link.auth === false)
      ).map((ownLink) => (
        <LineItemLink route={route} { ...ownLink } key={JSON.stringify(ownLink)} />
      ))
    )}
  </Fragment>
);

const renderLinks = ({ auth, ...rest }) => (
  auth ? renderAuth(rest) : renderUnAuth(rest)
);

const MobileNav = ({ auth, logOut, ...rest }) => (
  <ul
    id="mobile-menu-dropdown"
    className="side-nav right"
    style={{ maxWidth: '240px' }}
  >
    <li
      className="pad-0"
      style={{
        display: 'inline-block',
        width: '100%',
        height: '58px',
      }}
    >
      <Anchor
        id="LinkToHomeMobile"
        title="Home"
        to="/"
        classNames="brand-logo left waves-effect waves-light pad-l-1"
        styles={{ height: '100%' }}
      >
        <img
          src={LIGHT_LOGO}
          alt="TTYL Events Logo"
          style={{
            height: '60px',
            position: 'relative',
            bottom: '0',
          }}
        />
      </Anchor>
    </li>
    { renderLinks({ ...rest, auth }) }
    <SessionButtons auth={auth} logOut={logOut} />
  </ul>
);

const Nav = ({ auth, logOut, ...rest }) => (
  <div className="navbar-fixed">
    <nav>
      <div className="nav-wrapper">
        <h1 style={{display:'none'}}>
          { APPNAME }
        </h1>
        <Anchor
          id="LinkToHome"
          title="Home"
          to="/"
          classNames="brand-logo left waves-effect waves-light"
          styles={{ padding: '0', height: '100%' }}
        >
          <img
            src={LIGHT_LOGO}
            alt="TTYL Events Logo"
            style={{
              height: '60px',
              position: 'relative',
              top: '0',
            }}
          />
        </Anchor>
        <div className="container">
          { /* Desktop Nav */ }
          <ul className="left hide-on-med-and-down">
            { renderLinks({ ...rest, auth }) }
          </ul>
          <ul className="right hide-on-med-and-down">
            <SessionButtons auth={auth} logOut={logOut} />
          </ul>
        </div>
        <a
          id="MobileDeviceNavExpand"
          title="MobileDeviceNavExpand"
          href="#!"
          className="button-collapse right"
          data-activates="mobile-menu-dropdown"
          style={{ padding: '0 8px' }}
        >
          <i className="material-icons">menu</i>
        </a>
      </div>
    </nav>
  </div>
);

class Navigation extends PureComponent {
  render() {
    const { classNames, ...rest } = this.props;

    return (
      <section id={COMPONENT_NAME} className={classNames}>
        <Nav {...rest} />
        { /* Mobile Nav */ }
        <MobileNav {...rest} />
      </section>
    );
  }
}

Navigation.displayName = COMPONENT_NAME;

export default Navigation;
