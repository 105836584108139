import React, { memo } from 'react';

import HeaderBar from  './components/HeaderBar';
import Info from './components/Info';
import Preloader from './components/Preloader';

const COMPONENT_NAME = 'MyAccount';

const MyAccount = ({
  user,
  auth,
  loading,
  styles,
  history,
}) => {
  if (loading) {
    return <Preloader />;
  }
  if (!auth && !user) {
    history.replace('/');
  }

  return (
    <section id={COMPONENT_NAME}>
      <div style={{ padding: 0, ...styles, }}>
        <section id="Account Portal">
          <div className="row margin-t-2">
            <HeaderBar
              id="LoginHeader"
              level={4}
              classNames="row left-align black raised margin-0"
            >
              Account Information
            </HeaderBar>
          </div>
          <div className="row margin-t-2">
            <Info {...user} showLabels={true} />
          </div>
        </section>
      </div>
    </section>
  );
}

MyAccount.displayName = COMPONENT_NAME;

export default memo(MyAccount);
