export const getUserPropsObject = ({
  displayName,
  email,
  emailVerified,
  metadata,
  phoneNumber,
  photoURL,
  refreshToken,
  uid,
}) => ({
  displayName,
  email,
  emailVerified,
  metadata,
  phoneNumber,
  photoURL,
  refreshToken,
  uid,
});

export default {
  getUserPropsObject,
};
