import { createBrowserHistory } from 'history';

export default createBrowserHistory({
  /* pass a configuration object here if needed */
});

// import { createBrowserHistory } from 'history';
//
// export default {
//   history: createBrowserHistory({
//     /* pass a configuration object here if needed */
//   }),
//   isHome: (
//     routing && routing.location && routing.location.pathname &&
//     routing.location.pathname === '/home'
//   ),
// };
