/* eslint-disable */
import CONFIG from '../../Config/index.js';
const { APP } = CONFIG;
const { auth } = APP;

const testUser = {
  userid: '123',
  username: 'svazquez',
  firstname: 'Sean',
  lastname: 'Vazquez',
  company: {
    title: 'Teksupport',
    id: 'teksupport',
    url: 'https://www.residentadvisor.net/promoter.aspx?id=14688',
    sounds: {
      spotify: false,
      mixcloud: false,
      soundcloud: false,
    },
    socials: {
      facebook: 'https://www.facebook.com/teksupportnyc/',
      instagram: 'https://www.instagram.com/teksupport/?hl=en',
      twitter: 'https://twitter.com/teksupportnyc?lang=en',
    },
    logo: 'https://www.frontgatetickets.com/wp-content/uploads/2016/10/sgrerh.jpg',
    mission: 'SEAN TEST !!!',
    theme: {
      text: 'white',
      background: 'black',
      foreground: 'grey darken-4',
    },
  },
  email: 'sean@tekbeatz.com',
  phone: '6099033609',
  labels: true,
  address: {
    title: 'Billing Address',
    labels: true,
    street: {
      number: '71',
      name: 'evergreen rd.',
    },
    city: 'New Egypt',
    state: 'New Jersey',
    country: 'USA',
    zipcode: '08533',
  },
};

const create = ({ email, password, messages }) => (
  auth.createUserWithEmailAndPassword(
    email,
    password
  ).then(() => {
    console.log('createUserWithEmailAndPassword');
    console.log(auth.currentUser);
    // newMessage({
    //   from: 'creating a user',
    //   type: 'success',
    //   message: (
    //     messageEvaluator({
    //       ...messages,
    //     }, 'success') || 'We have successfully created a new user.'
    //   ),
    //   code: 200,
    // });
    return auth.currentUser;
  }).catch((error) => {
    console.log('createUserWithEmailAndPassword error');
    console.log(error);
    console.log(auth.currentUser);
    // newMessage({
    //   from: 'creating a user',
    //   type: 'error',
    //   message: (
    //     messageEvaluator({
    //       ...messages,
    //     }, 'error') || error.message
    //   ),
    //   code: error.code,
    // })
  })
);

const reset = ({ email }) => {
  var actionCodeSettings = {
    url: (
      `${APP.domainFull}?email=${email}`
    ),
    iOS: {
      bundleId: (
        `com.${APP.domain}.ios`
      ),
    },
    android: {
      packageName: {
        bundleId: (
          `com.${APP.domain}.android`
        ),
      },
      installApp: true,
      minimumVersion: '12',
    },
    handleCodeInApp: true,
    dynamicLinkDomain: (
      `${APP.domain}.page.link`
    ),
  };
  auth.sendPasswordResetEmail(
    email, actionCodeSettings
  ).then(() => {
    console.log('Reset sendPasswordResetEmail');
    console.log('Password reset email sent.');
  }).catch((error) => {
    console.log('Reset sendPasswordResetEmail error');
    console.log('Error occurred. Inspect error.code.');
    console.log(console.error);
    return error;
  });
};

const endSession = ({ messages }) => (
  auth.signOut().then(() => {
    console.log('signOut');
    console.log(auth.currentUser);
    // newMessage({
    //   from: 'signing out',
    //   type: 'success',
    //   message: (
    //     messageEvaluator({
    //       ...messages,
    //     }, 'success') || 'We have successfully signed you out.'
    //   ),
    //   code: 200,
    // })
  }).catch((error) => {
    console.log('signOut error');
    console.log(auth.currentUser);
    console.log(error);
    return error;
    // newMessage({
    //   from: 'signing out',
    //   type: 'error',
    //   message: (
    //     messageEvaluator({
    //       ...messages,
    //     }, 'error') || error.message
    //   ),
    //   code: error.code,
    // })
  })
);

const signIn = ({ email, password, messages }) => (
  !!email && !!password && (
    auth.signInWithEmailAndPassword(
      email,
      password
    ).then(() => {
      // newMessage({
      //   from: 'signing in',
      //   type: 'success',
      //   message: (
      //     messageEvaluator({
      //       ...messages,
      //     }, 'success') || 'We have successfully signed you in.'
      //   ),
      //   code: 200,
      // });
      console.log('signInWithEmailAndPassword');
      console.log(auth.currentUser);
      return auth.currentUser;
    }).catch((error) => {
      console.log('signInWithEmailAndPassword error');
      console.log(auth.currentUser);
      console.log(error);
      return error;
      // newMessage({
      //   from: 'signing in',
      //   type: 'error',
      //   message: (
      //     messageEvaluator({
      //       ...messages,
      //     }, 'error') || error.message
      //   ),
      //   code: error.code,
      // })
    })
  )
);

const startSession = props => (
  !!props && (
    auth.setPersistence(auth.Auth.Persistence.SESSION).then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      console.log('setPersistence');
      console.log(auth.currentUser);
      return signIn(props);
    }).catch((error) => {
      console.log('setPersistence error');
      console.log(auth.currentUser);
      console.log(error);
      return error;
      // newMessage({
      //   from: 'starting a session',
      //   type: 'error',
      //   message: (
      //     messageEvaluator({
      //       ...props.messages,
      //     }, 'error') || error.message
      //   ),
      //   code: error.code,
      // })

    })
  )
);

export default {
  testUser,
  session: {
    startSession,
    endSession,
  },
  reset,
  create,
};
