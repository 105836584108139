import React, { Fragment, PureComponent } from 'react';

import Promote from './Promote';

import HeaderBar from './components/HeaderBar';
import NewsComponent from './components/News';
import Preloader from './components/Preloader';

// import NEWS from '../Functions/News';

// const { getNewsRecent, getNewsHeadlines } = NEWS;

const COMPONENT_NAME = 'News';

class TheNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { headlines: [], recents: [], loading: true };
  }

  componentDidMount = () => this.load(this.props);

  load = ({ q }) => {
    const internal = [
      {
        title: 'CID TAKES OVER AVANT GARDNER - KINGS HALL',
        url: 'https://edm.com/events/ttyl-presents-cid-kings-hall-avant-gardner',
        urlToImage: 'https://storage.googleapis.com/ttylevents-cdn/events/AVANT_CID.PNG',
        source: {
          name: 'edm.com',
        },
        author: 'TTYL Presents',
        description: (`
          Don't Miss CID at Avant Gardner with Niko The Kid, Chris Kalaylee,
          Jay Roman, Kitty Pack and RS|AM.

          On Saturday, April 13th, TTYL Presents is bringing New York’s very
          own Grammy Award-Winning producer CID (real name Carlos Cid) to
          perform live at Kings Hall - Avant Gardner.

          Born of Spanish descent in Ourense, New York, CID started DJing at
          the ripe young age of 12. He quickly realized that the DJs he
          idolized were known primarily for their music production rather than
          their performances as DJs. CID was consequently inspired to produce
          his own music, and it soon became evident that he was not only a
          talented DJ but also a highly skilled engineer and producer.

          Fast forward to 2013 and CID won a Grammy for his production with
          Cedric Gervais' remix of "Summertime Sadness” by Lana Del Rey. He
          then continued on to release "Sweet Memories” with Kaskade in 2016
          and premiered his very own dance track titled "Together" that same
          year. From collaborating with Galantis on the duo’s original song
          “Pillow Fight” to working alongside Conrad Sewell on a major track
          titled “Secrets” with Atlantic's Big Beat Records, CID is a major
          player in the world of dance music - and an undeniable pleasure to
          witness live.

          If you’re a diehard house music fan in the New York area, we highly
          encourage you to check out CID @ Kings Hall - Avant Gardner. With
          direct support from Niko The Kid, as well as up-and-coming locals
          Chris Kalaylee, Jay Roman, Kitty Pack and RS|AM, this is a show you
          won’t want to miss!

          Very Limited $25 Tickets Available Now!!

          For those who love CID, don't miss out on his new radio show on
          Sirius XM, Tuesdays at 5:00 PM PST/8:00 PM EST on Diplo's Revolution
          Ch. 52, as well as his forthcoming collaboration with Don Diablo,
          "Fever."
        `),
        publishedAt: '4/2/2019',
      },
      {
        title: 'CID to Play Biggest Hometown Show',
        url: 'http://www.thenocturnaltimes.com/cid-to-play-biggest-hometown-show-to-date-at-kings-hall-avant-gardner/?fbclid=IwAR3gTMrYbGG49cVNzQpOjud5AIydZzVi0ndGgfyvSxDm3prFEnqyu5W1JIs',
        urlToImage: 'https://storage.googleapis.com/ttylevents-cdn/events/DonDiabloCID.png',
        source: {
          name: 'Nocturnal Times',
        },
        author: 'TTYL Presents',
        description: (`
          GRAMMY Award-winning producer/DJ CID (born Carlos Cid), is slated to
          play his biggest hometown set yet. On Saturday, April 13, the
          electronic dance music (EDM) mega-talent takes to the Kings Hall at
          Avant Gardner.

          Brought to you by TTYL Events, CID’s anticipated Kings Hall debut
          includes direct support from Niko The Kid, up & coming local DJ’s
          Chris Kalaylee, Jay Roman, Kitty Pack & RS|AM. Doors open @ 10pm.
          19+ to Enter.

          With a plethora of original tunes released across prominent
          electronic labels such as Musical Freedom, Big Beat Records,
          Spinnin’ Records, and SIZE Records, among others, CID has been
          pumping out jams since breaking out onto the scene in 2013. CID
          notably released alongside top-tier talent such as Kaskade, Armin
          van Buuren, and CeeLo Green.
        `),
        publishedAt: '4/5/2019',
      }
    ];
    // return Promise.all([
    //   getNewsRecent({ source: 'mtv-news', q }),
    //   getNewsHeadlines({ source: 'mtv-news', q }),
    // ]).then((results) => {
    //   const internal = (
    //     !!results.length > 0 &&
    //     !!results[0].data &&
    //     !!results[0].data &&
    //     !!results[0].data.length
    //   );
    //   let recents = (
    //     !!results &&
    //     !!results.length > 0 &&
    //     !!results[0].data &&
    //     !!results[0].data.articles &&
    //     results[0].data.articles
    //   );
    //   recents = recents.filter(recent => (
    //     !!recent && !!recent.title && (
    //       !(
    //         (
    //           recent.title.includes('Redirect') ||
    //           recent.title.includes('REDIRECT') ||
    //           recent.title.includes('redirect')
    //         ) && recent.title.length > 6
    //       )
    //     )
    //   ));
    //   let headlines = (
    //     !!results &&
    //     !!results.length > 0 &&
    //     !!results[1].data &&
    //     !!results[1].data.articles &&
    //     results[1].data.articles
    //   );
    //   headlines = headlines.filter(recent => (
    //     !!recent && !!recent.title && (
    //       !(
    //         (
    //           recent.title.includes('Redirect') ||
    //           recent.title.includes('REDIRECT') ||
    //           recent.title.includes('redirect')
    //         ) && recent.title.length > 6
    //       )
    //     )
    //   ));
    //   this.setState({ headlines, recents, loading: false });
    // })
      this.setState({ internal, loading: false });
  };

  render() {
    const { q } = this.props;
    const { internal, headlines, recents, loading } = this.state;
    const articlesLength = (q ? 3 : 6);
    return (
      <section id={`${COMPONENT_NAME}-DataLayer`} className="margin-tb-2">
        {
          loading
            ?
            <Preloader />
            :
            ( !q ?
              (
                <Fragment>
                  {(
                    (
                      !!internal && internal.length > 0
                    ) || (
                      !!headlines && headlines.length > 0
                    )
                  ) && (
                    <Fragment>
                      <HeaderBar
                        id="CurrentHighlightsHeader"
                        level={4}
                        classNames="row left-align black raised margin-0"
                      >
                        Current Highlights
                      </HeaderBar>
                      <div className="row uppercase">
                        <NewsComponent
                          internal={internal}
                          articles={headlines}
                          articlesLength={articlesLength}
                        />
                        <Promote promote={true} />
                      </div>
                    </Fragment>
                  )}
                  { !!recents && recents.length > 0 && (
                    <Fragment>
                      <HeaderBar
                        id="RecentNewsHeader"
                        level={4}
                        classNames="row left-align black raised margin-0"
                      >
                        Recent News
                      </HeaderBar>
                      <div className="row uppercase">
                        <NewsComponent
                          articles={recents}
                          articlesLength={articlesLength}
                        />
                        <Promote promote={true} />
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )
              :
              (
                <Fragment>
                  <HeaderBar
                    id={`${q ? `RelatedNewsTo${q}` : 'RecentNews' }-Header`}
                    level={4}
                    classNames="row left-align black raised margin-0"
                  >
                    { q ? `Related News to ${q}` : 'Recent News' }
                  </HeaderBar>
                  <div className="row uppercase">
                    <NewsComponent
                      articles={recents}
                      articlesLength={articlesLength}
                    />
                  </div>
                </Fragment>
              )
            )
        }
      </section>
    );
  }
}

TheNews.displayName = COMPONENT_NAME;

export default TheNews;
