/* eslint-disable no-restricted-globals */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the old content will have been purged and
                  // the fresh content will have been added to the cache.
                  // It's the perfect time to display a "New content is
                  // available; please refresh." message in your web app.
                  const conf = confirm(
                    `New content is available! Would you like to refresh
                    TTYL Events?`
                  );
                  if (conf) {
                    if (!global.location.hash) {
                      global.location.hash = '#reloading';
                      global.location.reload(true);
                    } else {
                      global.location.hash = '#reloaded';
                    }
                  } else {
                    alert(
                      `Ok we will not refresh but, you are missing out on our
                      recent update!`
                    );
                  }
                } else {
                  // At this point, everything has been precached.
                  // It's the perfect time to display a
                  // "Content is cached for offline use." message.
                  // console.log('Content is cached for offline use.');
                }
              }
            };
          };
        })
        .catch(error => {
          alert(
            `Seems like some of our features required to run this application
            cannot run on your system. Please update or have your system
            checked for errors. We will try to continue to work as best as we
            can! Thanks, TTYL Events / Support@TTYLEvents.com`,
            error
          );
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
