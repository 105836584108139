import React, { memo } from 'react';

const Image = ({
  classNames = 'responsive-img',
  alt = 'Image',
  title = 'Image',
  src = false,
  styles,
}) => (
  src && (
    <img
      classNames={classNames}
      alt={alt}
      title={title}
      src={src}
      style={styles}
    />
  )
);

export default memo(Image);
