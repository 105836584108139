import React from 'react';

const Subscribe = () => (
  <div
   id="ToggleMailChimpSignUp"
   className="ttyl-modal"
   style={{ display:'none' }}
 >
   <div id="BackgroundCloseMailChimp" className="ttyl-modal-background"></div>
   <div className="ttyl-modal-content container pad-tb">
     <div className="margin-t margin-b-2 center-align">
       <button id="CloseMailChimpSignUp" className="btn btn-primary btn-large fluid">
         Close
       </button>
     </div>
     {/* <!--Start mc_embed_signup--> */}
     <div id="mc_embed_signup">
       <form
         action="https://ttylevents.us17.list-manage.com/subscribe/post?u=12366df668e5cce2ce869ed7a&amp;id=d36b8414f7"
         method="post"
         id="mc-embedded-subscribe-form"
         name="mc-embedded-subscribe-form"
         className="validate" target="_blank"
         noValidate={true}
       >
         <div id="mc_embed_signup_scroll">
           <h2>Subscribe to our mailing list</h2>
           <div className="indicates-required">
             <span className="asterisk">*</span>
             indicates required
           </div>
           <div className="mc-field-group">
             <label htmlFor="mce-EMAIL">
               Email Address
               <span className="asterisk">*</span>
             </label>
             <input
               type="email"
               defaultValue=""
               name="EMAIL"
               className="required email"
               id="mce-EMAIL"
             />
           </div>
           <div className="mc-field-group">
             <label htmlFor="mce-FNAME">First Name </label>
             <input
               type="text"
               defaultValue=""
               name="FNAME"
               className=""
               id="mce-FNAME"
             />
           </div>
           <div className="mc-field-group">
             <label htmlFor="mce-LNAME">Last Name </label>
             <input
               type="text"
               defaultValue=""
               name="LNAME"
               className=""
               id="mce-LNAME"
             />
           </div>
           <div id="mce-responses" className="clear">
             <div
               className="response"
               id="mce-error-response"
               style={{ display:'none' }}
             ></div>
             <div
               className="response"
               id="mce-success-response"
               style={{ display:'none' }}
             ></div>
           </div>

           {/*
             <!--
               real people should not fill this in and expect good things -
               do not remove this or risk form bot signups
             -->
           */}
           <div
             style={{
               position: 'absolute',
               left: '-5000px',
             }}
             aria-hidden="true"
           >
             <input
               type="text"
               name="b_12366df668e5cce2ce869ed7a_d36b8414f7"
               tabIndex="-1"
               defaultValue=""
             />
          </div>
           <div className="clear">
             <input
               type="submit"
               defaultValue="Subscribe"
               name="subscribe"
               id="mc-embedded-subscribe"
               className="btn btn-primary btn-large margin-0"
             />
           </div>
         </div>
       </form>
     </div>
     {/* <!--End mc_embed_signup--> */}
     <div className="margin-t-2 center-align">
       <button
         id="CloseMailChimpSignUpBottom"
         className="btn btn-primary btn-large fluid"
       >
        Close
       </button>
     </div>
   </div>
 </div>
);

export default Subscribe;
