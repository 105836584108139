/* eslint-disable no-mixed-operators */

import React, { PureComponent } from 'react';

import Footer from './Footer';

import BASEModal from '../../Modal';

class Button extends PureComponent {
  componentDidMount() {
    if (this.props.id && this.props.showid && !!global.EBWidgets.createWidget) {
      return global.EBWidgets.createWidget({
        // Required
        widgetType: 'checkout',
        eventId: this.props.showid,
        iframeContainerId: `eventbrite-widget-container-${this.props.showid}`,

        // Optional

        iframeContainerHeight: this.props.height || 425,
        // Widget height in pixels. Defaults to a min of 425px if not provided

        onOrderComplete: this.onOrder(this.props.showid),
        // Method called when an order has successfully completed
      });
    }
  }

  onOrder = showid => (
    !!showid && (
      !!this.props.onOrder
        ?
        this.props.onOrder(showid)
        :
        global.alert(
          'Success! You will receive more information via Eventbrite.com!'
        )
    ) || global.alert(
      'An error occurred... Please visit Eventbrite.com for more information.'
    )
  );

  toggle = () => this.setState({ shown: !this.state.shown });

  render() {
    const { id, showid } = this.props;
    return (
      !!id && !!showid && (
        <section id={id}>
          <BASEModal
            id={`${showid}-Modal`}
            open="Continue"
            footerChildren={<Footer id={id} />}
          >
            {/* Eventbrite Modal */}
            <div id={`eventbrite-widget-container-${showid}`}>
            </div>
          </BASEModal>
        </section>
      ) || null
    );
  }
}

export default Button;
