import React from 'react';
import moment from 'moment';

import Anchor from '../Anchor';
import Text from '../Text';

const COMPONENT_NAME = 'TTYLEventsShowsListItem';

const TTYLEventsOld = ({
    clientid,
    eventid,
    name,
    host,
    pastevent,
    textid,
    flyer,
    venue,
    datetime,
    description,
    disclaimer,
    hostids,
    tickets,
    tier,
    tiers,
    edit,
    isTest,
}) => (
  <div
    data-module-name={COMPONENT_NAME}
    className={
      `col s12 m6 l4 show-wrapper margin-t-0 ${
        isTest ? 'yellow' : ''
      }`
    }
  >
    <Anchor
      id={`To-${eventid}`}
      url={!!tickets.url ? tickets.url : `https://pay.ttylevents.com/?event=${eventid}&frame=true`}
      title={`Link To: ${name} Hosted By: ${host}`}
    >
      <div className="valign-wrapper white-text">
        <div className="row margin-0">
          <div className="col s12 margin-b-1">
            <div className="row margin-0">
              <div
                className="col s4 black white-text center-align raised show-date"
                style={{ height: '54px' }}
              >
                <div className="row margin-0">
                  <div className="col s12">
                    { moment(datetime).format('MMMM') }
                  </div>
                  <div className="col s12 font-size-3">
                    { moment(datetime).format('DD') }
                  </div>
                </div>
              </div>
              <div className="col s8 ttyl-grey valign-wrapper raised show-host">
                { !!host && (
                  <div className="fluid center-align">
                    <Text classNames="card-title font-size-1">
                      { host }
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col s12">
            { !!name && (
              <div className="valign-wrapper show-title ttyl-grey raised pad-0">
                <div className="fluid center-align">
                  <Text classNames="card-title font-size-2">
                    { name }
                  </Text>
                </div>
              </div>
            )}
          </div>
          { flyer && (
            <div className="col s12 waves-effect waves-light">
              <img
                id={`${name} Flyer`}
                className="raised fluid"
                alt={`${name} Flyer`}
                src={flyer}
                style={{
                  maxWidth: '100%',
                  position: 'relative',
                  bottom: '-5px',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Anchor>
  </div>
);

TTYLEventsOld.displayName = 'TTYLEventsOld';

export default TTYLEventsOld;
