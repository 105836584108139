import React, { Fragment } from 'react';

const Street = ({ street, showLabels }) => (
  <div className="collection-item">
    { showLabels && (<b>Address: </b>) }
    { !!street && (
      <Fragment>
        { street.number && <span>{ street.number }</span> }
        { street.name && <span>{ street.name } </span> }
      </Fragment>
    )}
  </div>
);

const Lines = ({ lines }) => (
  !!lines && lines.length > 0 && lines.map((line) => (
    <div className="collection-item" key={JSON.stringify(line)}>{`${line}`}</div>
  ))
);

const CityStateCountryZip = ({
  city,
  state,
  country,
  zipcode,
  showLabels,
}) => (
  <Fragment>
    <div className="collection-item">
      { showLabels && <b>City: </b> } {city}
    </div>
    <div className="collection-item">
      { showLabels && <b>State: </b> } {state}
    </div>
    <div className="collection-item">
      { showLabels && <b>Country: </b> } {country}
    </div>
    <div className="collection-item">
      { showLabels && <b>Zip Code: </b> } {zipcode}
    </div>
  </Fragment>
);

const renderAddress = addy => (
  <div className="col s12 margin-tb">
    <div className="ttyl-grey">
      {
        !!addy.title
          ?
          (<div><h5>{ addy.title }</h5></div>)
          :
          null
      }
      <Street { ...addy } />
      <Lines { ...addy } />
      <CityStateCountryZip { ...addy } />
    </div>
  </div>
);

const Address = addy => (
  addy ? renderAddress(addy) : null
);

export default Address;
