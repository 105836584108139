import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import HeaderBar from './components/HeaderBar';
import Preloader from './components/Preloader';
import ShowsComponent from './components/Shows';

import SHOWS from '../Functions/Shows';

const { getShows } = SHOWS;

const COMPONENT_NAME = 'Shows';

const ShowsDataLayer = ({
  edit,
  id,
  shows,
  pastShows,
  styles,
  loading,
  auth,
  user,
  header,
}) => (
  <section id={`${COMPONENT_NAME}-DataLayer`} className="margin-tb">
    {
      loading
        ?
        <Preloader />
        :

        <Fragment>
          { header && (shows.length > 0)  && (
            <HeaderBar
              id="UpcomingShowsHeader"
              classNames="row left-align black raised margin-tb-2 margin-lr-0"
              level={4}
            >
              Upcoming Shows
            </HeaderBar>
          )}
          <div className="row uppercase">
            <ShowsComponent
              showid={id}
              shows={shows}
              auth={auth}
              user={user}
              edit={edit}
            />
          </div>
          { header && (pastShows.length > 0) && (
            <HeaderBar
              id="UpcomingShowsHeader"
              classNames="row left-align black raised margin-tb-2 margin-lr-0"
              level={4}
            >
              Past Shows
            </HeaderBar>
          )}
          <div className="row uppercase">
            <ShowsComponent
              showid={id}
              pastShows={pastShows}
              auth={auth}
              user={user}
              edit={edit}
            />
          </div>
        </Fragment>
    }
  </section>
);

const buildReq = id => ({
  action: 'findBy',
  params: {
    where: 'showid',
    which: id,
  },
});

const orderByDate = list => (
  list.sort((a, b) => (new Date(a.datetime) < new Date(b.datetime)) ? 1 : -1)
);


class TheShows extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      id: false,
      auth: false,
      shows: [],
      loading: true,
      user: false,
    };
  }

  componentDidMount = () => this.load(this.props);

  findShows = id => id ? getShows(buildReq(id)) : getShows();

  show = () => setTimeout(() => (
    this.setState({ loading: false })
  ), 750);

  load = ({ id, auth, user, edit }) => (
    this.findShows(id).then((shows) => {
      const newShows = [];
      const pastShows = [];
      shows.forEach((show) => (
        ((new Date(show.datetime)) > (new Date()))
          ? newShows.push(show) : pastShows.push(show)
      ));
      this.setState({
        edit,
        id,
        auth,
        user,
        shows: orderByDate(newShows),
        pastShows: orderByDate(pastShows),
      }, this.show)
    })
  );

  render = () => (<ShowsDataLayer { ...this.state } { ...this.props} />);
}

TheShows.displayName = `${COMPONENT_NAME}-Provider`;

const mapStateToProps = (state, { edit, id, auth, user, header }) => ({
  edit, id, auth, user, header,
});

export default connect(mapStateToProps)(TheShows);
