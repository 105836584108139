import React, { Fragment, PureComponent } from 'react';

import Button from './Button';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shown: false,
    };
  }
  toggle = shown => (
    this.setState({ shown: !shown }, () => window.scrollTo(0, 0))
  );
  render() {
    const {
      id,
      children,
      footerChildren,
      open,
      close,
      openButtonClasses,
      closeButtonClasses,
      modalClasses,
    } = this.props;

    const { shown } = this.state;

    return (
      <Fragment>
        <Button
          classNames={openButtonClasses || 'btn black fluid raised'}
          onClick={() => this.toggle(shown)}
          styles={{
            padding: '10px 0',
            height: '50px',
          }}
        >
          { open || 'Open' }
        </Button>
        <div
          className="fluid"
          style={{ display: shown ? 'block' : 'none', position: 'static' }}
        >
          <div
            className="ttyl-grey"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 5,
            }}
          />
          <div
            id={id}
            className={
              `scale-transition ${
                modalClasses || 'ttyl-modal black raised'
              } ${shown ? 'scale-in' : 'scale-out'}`
            }
            style={{
              display: shown ? 'block' : 'none',
              margin: 0,
              width: '100%',
              position: 'absolute',
              top: '74px',
              left: 0,
              zIndex: 10,
            }}
          >
            <button
              className="btn black fluid raised"
              onClick={() => this.toggle(shown)}
              style={{ height: '50px' }}
            >
              { close || 'Close' }
            </button>
            { children && (<div>{ children }</div>)}
            <button
              className={closeButtonClasses || 'btn black fluid raised'}
              onClick={() => this.toggle(shown)}
              style={{
                padding: '10px 0',
                height: '50px',
              }}
            >
              { close || 'Close' }
            </button>
            { !!footerChildren && (
              <div className="modal-footer">
                { footerChildren }
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Modal;
