import React from 'react';

const Footer = ({ id }) => (
  <section id={`${id}-Footer`}>
    <p>
      {`
        The ticketing for this event is handled via Eventbrite.com . We are
        not affiliated with Eventbrite or Eventbrite.com, or any affiliated,
        or related company or entity of the like. Please visit our
        Terms and Conditions.
      `}
    </p>
  </section>
);

export default Footer;
