const firebaseConfig = {
  apiKey: 'AIzaSyAVw0RanmuQW1zzS1NfvPCh9ZMFoeRoSig',
  authDomain: 'ttylevents-app-prod-admin.firebaseapp.com',
  databaseURL: 'https://ttylevents-app-prod-admin.firebaseio.com',
  projectId: 'ttylevents-app-prod-admin',
  storageBucket: '',
  messagingSenderId: '773794770953',
  appId: '1:773794770953:web:8366d170eb31aed7',
};

export default firebaseConfig;
