import React, { memo } from 'react';
import moment from 'moment';

import Address from './Address';
import Avatar from './Images/Avatar';

const Info = ({
  username,
  firstname,
  lastname,
  displayName,
  email,
  phoneNumber,
  photoURL,
  metadata,
  address,
  showLabels,
}) => (
  <div className="row">
    { photoURL && (
      <div className="col s12">
        <div className="ttyl-grey pad">
          <Avatar
            src={photoURL}
            title={`${displayName}'s Avatar`}
            alt={`${displayName}'s Avatar`}
            classNames="circle responsive-img"
            styles={{ width: '50px' }}
          />
        </div>
      </div>
    )}
    <div className="col s12 m6 margin-tb">
      <div className="ttyl-grey pad">
        { displayName && (
          <div className="collection-item">
            { showLabels && <span><b>Name: </b></span> }
            { displayName }
          </div>
        )}
        { username && (
          <div className="collection-item">
            { showLabels && <span><b>Username: </b></span> }
            { username }
          </div>
        )}
        { firstname && (
          <div className="collection-item">
            { showLabels && <span><b>First Name: </b></span> }
            { firstname }
          </div>
        )}
        { lastname && (
          <div className="collection-item">
            { showLabels && <span><b>Last Name: </b></span> }
            { lastname }
          </div>
        )}
        { email && (
          <div className="collection-item">
            { showLabels && <span><b>Email: </b></span> }
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        )}
        { phoneNumber && (
          <div className="collection-item">
            { showLabels && <span><b>phoneNumber: </b></span> }
            <a href={`tel:${phoneNumber}`}>{ phoneNumber }</a>
          </div>
        )}
      </div>
    </div>
    { !!address && <Address showLabels={showLabels} { ...address } />}
    { !!metadata && (
      <div className="col s12 m6 margin-tb">
        <div className="ttyl-grey pad">
          { metadata.lastSignInTime && (
            <div className="collection-item">
              { showLabels && <span><b>Last Login: </b></span> }
              { moment(metadata.lastSignInTime).format('LLLL') }
            </div>
          )}
          { metadata.creationTime && (
            <div className="collection-item">
              { showLabels && <span><b>Created At: </b></span> }
              { moment(metadata.creationTime).format('LLLL') }
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);

Info.displayName = 'Info';

export default memo(Info);
