const sec = 'https://';
const domain = 'ttylevents.com';

const APP = {
  // APP Domains
  sec,
  domain,
  domainFull: (sec + domain),
};

export const APPNAME = 'TTYL Events';

export const COLORS = {
  main: 'black',
  second: 'black',
  third: 'grey',
};

export const SOCIAL = {
  instagram: (
    'https://www.instagram.com/ttylevents/'
  ),
};

const storage = (
  'https://storage.googleapis.com/ttylevents-cdn'
);

const assets = (
  storage + '/assets'
);

const DARK_LOGO = (
  assets + '/ttyl_logo_black.png'
);

const LIGHT_LOGO = (
  assets + '/ttyl_logo_white.png'
);

const LOGO = (
  assets + '/ttyl_logo_black.png'
);

const BANNER = (
  assets + '/blackwhite.jpg'
);

export const ASSETS = {
  LOGO,
  DARK_LOGO,
  LIGHT_LOGO,
  BANNER,
};

export default {
  APP,
  APPNAME,
  COLORS,
  ASSETS,
  SOCIAL,
};
