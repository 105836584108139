import React from 'react';

import EventbriteButton from './Button';
import EventbriteModal from './Modal';

const Button = ({ displayid, showid }) => (
  !!displayid
    ?
    <EventbriteButton showid={showid} displayid={displayid} />
    :
    null
);

const Modal = ({ displayid, showid }) => (
  !!displayid
    ?
    <EventbriteModal showid={showid} displayid={displayid} />
    :
    null
);

export default ({ button, modal, showid, displayid }) => (
  !!button && !modal
    ?
    <Button showid={showid} displayid={displayid} />
    :
    <Modal showid={showid} displayid={displayid} />
);
