import axios from 'axios';

const eventbriteToken = 'E5TWYKRSZL3L5BO5TT7R';

const clientid = 'ttylevents-app';

const buildUrl = path => (
  `https://us-central1-ttylevents-app-dev.cloudfunctions.net/${path}`
);

const getEventBrite = () => (
  axios.get(
    `https://www.eventbriteapi.com/v3/users/me/?token=${eventbriteToken}`
  ).then(({ data }) => ({ eventBrite: data })).catch((err) => err)
);

const getShows = req => (
  axios.post(buildUrl('events'), { clientid, ...req }).then(
    ({ data }) => data
  ).catch((err) => err)
);

export default {
  getEventBrite,
  getShows,
};
