import React, { memo } from 'react';
import { Redirect } from 'react-router';

import Button from './components/Button';
import HeaderBar from  './components/HeaderBar';

const COMPONENT_NAME = 'UserPortal';

const UserPortal = ({
  id = COMPONENT_NAME,
  auth,
  signInWithGoogle,
  signInWithFacebook,
}) => (
  auth ? <Redirect to="/account" /> : (
    <section data-module-name={COMPONENT_NAME}>
      <div id={id} className="row margin-t-2">
        <HeaderBar
          id="LoginHeader"
          level={4}
          classNames="row left-align black raised margin-0"
        >
          Sign In/Sign Up!
        </HeaderBar>
      </div>
      <div className="row">
        <Button
          id="SignInGoogle"
          classNames="btn-primary btn-large fluid"
          title="Sign In With Google"
          onClick={signInWithGoogle}
        />
      </div>
      <div className="row">
        <Button
          id="SignInFacebook"
          classNames="btn-primary btn-large fluid disabled"
          title="Sign In With Facebook"
          onClick={signInWithFacebook}
          disabled="disabled"
        />
      </div>
    </section>
  )
);

export default memo(UserPortal);
