import React, { Fragment } from 'react';

import ListItem from './ListItem'

import proptypes from '../proptypes';

import Preloader from '../Preloader';

const ArticlesMapping = ({ articles, articlesLength }) => (
  articles.slice(0, articlesLength)
    .filter(({ title, url, description, content }) => (
      !!title && !!url && (!!content || !!description)
    ))
    .map((article, index) => (
      <ListItem
        key={JSON.stringify(article)}
        { ...article }
      />
    ))
);

const InternalMapping = ({ internal  }) => (
  internal.map((article, index) => (
    <ListItem
      key={JSON.stringify(article)}
      { ...article }
    />
  ))
);

const RenderList = ({ articles, internal, articlesLength }) => (
  <Fragment>
    { !!internal && (
      <Fragment>
        <InternalMapping internal={internal} /><br />
      </Fragment>
    )}
    { !!articles && (
      <ArticlesMapping articles={articles} articlesLength={articlesLength} />
    )}
  </Fragment>
);

const List = props => (
  !!props && !!props.articles
    ?
    <RenderList {...props} />
    :
    <Preloader />
);

List.displayName = 'NewsList';

List.propTypes = {
  articles: proptypes.typeCheck.arrOfObj,
};

List.defaultProps = {
  articles: false,
};

export default List;
