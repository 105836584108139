export const links = [
  {
    name: 'Account',
    link: '/account',
    auth: true,
  },
  {
    name: 'Shows',
    link: '/shows',
    auth: true,
  },
  {
    name: 'Our Terms',
    link: '/terms',
    auth: false,
  },
  {
    name: 'Contact Us',
    link: '/contact',
    auth: false,
  },
  {
    name: 'Contact Us',
    link: '/contact',
    auth: true,
  },
];

export default {
  links,
};
