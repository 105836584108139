import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import TTYLEventsOld from './TTYLEventsOld';

import Eventbrite from './Eventbrite';

import proptypes from '../proptypes';

import Anchor from '../Anchor';
import Text from '../Text';

const COMPONENT_NAME = 'ShowsListItem';

const ListItem = ({
  id,
  showid = id,
  eventid, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  eventbrite,// EVENTBRITE API Listing
  host,
  title,
  name, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  flyer,
  artists,
  genres,
  datetime,
  posted,
  tickets, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  vip,
  description,
  disclaimer,
  view,
  edit,
  isTest,
}) => (
  (!!eventbrite && (
    <Eventbrite showid={showid} {...eventbrite} />
  )) || (
    (!!eventid && !!name && !!tickets)
      ?
      <TTYLEventsOld {...{
        name,
        eventid,
        host,
        flyer,
        artists,
        genres,
        datetime,
        posted,
        tickets,
        vip,
        description,
        disclaimer,
        view,
        edit,
        isTest,
      }} />
      :
      <div
        data-module-name={COMPONENT_NAME}
        className={
          `col s12 m6 l4 show-wrapper margin-t-0 ${
            isTest ? 'yellow' : ''
          }`
        }
      >
        <Anchor
          id={`To-${showid}`}
          to={!!edit ? `/show/${showid}/edit` : `/show/${showid}`}
          title={`Link To: ${title} Hosted By: ${host}`}
        >
          <div className="valign-wrapper white-text">
            <div className="row margin-0">
              <div className="col s12 margin-b-1">
                <div className="row margin-0">
                  <div className="col s4 black white-text center-align raised show-date">
                    <div className="row margin-0">
                      <div className="col s12">
                        { moment(datetime).format('MMMM') }
                      </div>
                      <div className="col s12 font-size-3">
                        { moment(datetime).format('DD') }
                      </div>
                    </div>
                  </div>
                  <div className="col s8 ttyl-grey valign-wrapper raised show-host">
                    { !!host && (
                      <div className="fluid center-align">
                        <Text classNames="card-title font-size-1">
                          { host }
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col s12">
                { !!title && (
                  <div
                    className="valign-wrapper show-title ttyl-grey raised pad-0"
                    style={{
                      maxHeight: '80px',
                    }}
                  >
                    <div className="fluid center-align">
                      <Text classNames="card-title font-size-1">
                        { title }
                      </Text>
                    </div>
                  </div>
                )}
              </div>
              { flyer && (
                <Fragment>
                  <div className="col s12 waves-effect waves-light show-on-small hide-on-med-and-up">
                    <img
                      id={`${title} Flyer`}
                      className="raised fluid"
                      alt={`${title}-Flyer`}
                      src={flyer}
                      style={{
                        maxWidth: '100%',
                        position: 'relative',
                        bottom: '-5px',
                      }}
                    />
                  </div>
                  <div
                    className="col s12 waves-effect waves-light extended show-on-med hide-on-small-only hide-on-large-only"
                    style={{
                      overflow: 'hidden',
                      height: '260px',
                    }}
                  >
                    <img
                      id={`${title} Flyer`}
                      className="raised fluid"
                      alt={`${title}-Flyer`}
                      src={flyer}
                      style={{
                        maxWidth: '100%',
                        position: 'relative',
                        bottom: '-5px',
                      }}
                    />
                  </div>
                  <div
                    className="col s12 waves-effect waves-light extended show-on-large hide-on-med-only hide-on-small-only"
                    style={{
                      overflow: 'hidden',
                      height: '235px',
                    }}
                  >
                    <img
                      id={`${title} Flyer`}
                      className="raised fluid"
                      alt={`${title}-Flyer`}
                      src={flyer}
                      style={{
                        maxWidth: '100%',
                        position: 'relative',
                        bottom: '-5px',
                      }}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Anchor>
      </div>
  )
);

ListItem.displayName = COMPONENT_NAME;

ListItem.propTypes = {
  edit: proptypes.typeCheck.bool,
  showid: proptypes.typeCheck.string,
  genre: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.arrOfStr,
  datetime: PropTypes.oneOfType([
    proptypes.typeCheck.obj,
    proptypes.typeCheck.string,
  ]),
  posted: proptypes.typeCheck.obj,
  tickets: proptypes.typeCheck.obj,
  vip: proptypes.typeCheck.arrOfObj,
  description: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  view: PropTypes.bool,
};

ListItem.defaultProps = {
  edit: false,
  showid: false,
  genre: false,
  host: false,
  title: false,
  flyer: false,
  artists: false,
  datetime: false,
  posted: false,
  tickets: false,
  vip: false,
  description: false,
  disclaimer: false,
  view:  false,
};

export default ListItem;
