/* eslint-disable no-mixed-operators */

import React, { PureComponent } from 'react';

import BASEButton from '../../Button';

class Modal extends PureComponent {
  componentDidMount() {
    if (this.props.id && this.props.showid && !!global.EBWidgets.createWidget) {
      return global.EBWidgets.createWidget({
        // Required
        widgetType: 'checkout',
        eventId: this.props.showid,
        modal: true,
        modalTriggerElementId: `eventbrite-widget-modal-trigger-${this.props.showid}`,

        // Optional

        iframeContainerHeight: this.props.height || 425,
        // Widget height in pixels. Defaults to a min of 425px if not provided

        onOrderComplete: this.onOrder(this.props.showid),
        // Method called when an order has successfully completed
      });
    }
  }

  onOrder = showid => (
    !!showid && (
      !!this.props.onOrder
        ?
        this.props.onOrder(showid)
        :
        global.alert(
          'Success! You will receive more information via Eventbrite.com!'
        )
    ) || global.alert(
      'An error occurred... Please visit Eventbrite.com for more information.'
    )
  );

  toggle = () => this.setState({ shown: !this.state.shown });

  render() {
    const { showid, displayid } = this.props;
    return (
      !!showid && !!displayid && (
        <section id={`EventBrite-Modal-Widget-${showid}`}>
          { displayid && (
            <noscript>
              {/* Noscript content for added SEO */}
              <a
                href={`https://${displayid}.eventbrite.com`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Buy Tickets on Eventbrite
              </a>
            </noscript>
          )}
          {showid && (
            <BASEButton id={`eventbrite-widget-modal-trigger-${showid}`}>
              {/* Eventbrite Button */}
              Continue
            </BASEButton>
          )}
        </section>
      ) || null
    );
  }
}

export default Modal;
